import {
	imgSampleDocumentsCambridge,
	imgSampleDocumentsDuolingo,
	imgSampleDocumentsIelts,
	imgSampleDocumentsOet,
	imgSampleDocumentsPassport,
	imgSampleDocumentsProofOfFunds,
	imgSampleDocumentsPte,
	imgSampleDocumentsToefl,
	imgSampleDocumentsTranscript,
	imgVerificationEnglishTest,
	imgVerificationLetterOfAcceptance,
	imgVerificationPassport,
	imgVerificationProofOfFunds,
	imgVerificationStudyPermitLetter,
	imgVerificationTranscript,
} from '@halp/images';
import type {SurfaceColor} from '@halp/ui/Surface/type';
import type {
	FunnelStageMap,
	ProgressCardStages,
	StudentDocumentTypes,
} from '@halp/util/constants';
import type {StaticImageData} from 'next/image';

export type InstitutionLevel = 'postSecondary' | 'gradeTwelve' | 'gradeTen';

// Remove once we have lined up student dash progress cards and funnel stage options
export const funnelStageToProgressMap: FunnelStageMap<ProgressCardStages> = {
	SIGNUP: 'GET_STARTED',
	VERIFICATION: 'GET_STARTED',
	GET_STARTED: 'GET_STARTED',
	DISCOVER: 'DISCOVER',
	PREP: 'PREP',
	SUBMIT: 'PREP',
	ACCEPTED: 'ACCEPTED',
	VISA: 'ACCEPTED',
	SET_UP: 'ACCEPTED',
	PLACED: 'ACCEPTED',
};

export type VerificationDocumentType =
	| 'PASSPORT'
	| 'ENGLISH_TEST'
	| 'TRANSCRIPT'
	| 'PROOF_OF_FUNDS'
	| 'STUDY_PERMIT_LETTER'
	| 'LETTER_OF_ENROLLMENT';

export const VERIFICATION_COLORS: Record<
	VerificationDocumentType,
	SurfaceColor
> = {
	PASSPORT: 'purple',
	ENGLISH_TEST: 'blue',
	TRANSCRIPT: 'yellow',
	PROOF_OF_FUNDS: 'red',
	STUDY_PERMIT_LETTER: 'sky-blue',
	LETTER_OF_ENROLLMENT: 'red',
};

export const VERIFICATION_ICONS: Record<
	VerificationDocumentType,
	StaticImageData
> = {
	PASSPORT: imgVerificationPassport,
	ENGLISH_TEST: imgVerificationEnglishTest,
	TRANSCRIPT: imgVerificationTranscript,
	PROOF_OF_FUNDS: imgVerificationProofOfFunds,
	STUDY_PERMIT_LETTER: imgVerificationStudyPermitLetter,
	LETTER_OF_ENROLLMENT: imgVerificationLetterOfAcceptance,
};

export const VERIFICATION_DOCUMENT_OPTIONS: Record<
	VerificationDocumentType | 'undergradTranscripts',
	StudentDocumentTypes[]
> = {
	PASSPORT: ['PASSPORT'],
	ENGLISH_TEST: [
		'IELTS',
		'TOEFL',
		'DUOLINGO',
		'PTE',
		'CAMBRIDGE',
		'OET',
		'ENGLISH_PROFICIENCY_TEST_EXEMPTION',
	],
	TRANSCRIPT: ['G10_TRANSCRIPT', 'G11_TRANSCRIPT', 'G12_TRANSCRIPT'],
	undergradTranscripts: [
		'BACHELORS_TRANSCRIPT',
		'DIPLOMA_TRANSCRIPT',
		'BACHELORS_GRADUATION_CERTIFICATE',
	],
	PROOF_OF_FUNDS: ['FINANCIAL_DOCUMENTS'],
	STUDY_PERMIT_LETTER: ['STUDY_PERMIT_LETTER'],
	LETTER_OF_ENROLLMENT: ['LETTER_OF_ENROLLMENT'],
};

export const VERIFICATION_DOCUMENT_REQUIREMENTS: Record<
	VerificationDocumentType,
	string[]
> = {
	PASSPORT: [
		'student_dashboard.dashboard.whats_next.verification_documents_modal.passport.requirement_1',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.passport.requirement_2',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.passport.requirement_3',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.passport.requirement_4',
	],
	ENGLISH_TEST: [
		'student_dashboard.dashboard.whats_next.verification_documents_modal.english_test.requirement_1',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.english_test.requirement_2',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.english_test.requirement_3',
	],
	TRANSCRIPT: [
		'student_dashboard.dashboard.whats_next.verification_documents_modal.transcript.requirement_1',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.transcript.requirement_2',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.transcript.requirement_3',
	],
	PROOF_OF_FUNDS: [
		'student_dashboard.dashboard.whats_next.verification_documents_modal.proof_of_funds.requirement_1',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.proof_of_funds.requirement_2',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.proof_of_funds.requirement_3',
	],
	STUDY_PERMIT_LETTER: [
		'student_dashboard.dashboard.whats_next.verification_documents_modal.study_permit_letter.requirement_1',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.study_permit_letter.requirement_2',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.study_permit_letter.requirement_3',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.study_permit_letter.requirement_4',
	],
	LETTER_OF_ENROLLMENT: [
		'student_dashboard.dashboard.whats_next.verification_documents_modal.letter_of_enrollment.requirement_1',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.letter_of_enrollment.requirement_2',
		'student_dashboard.dashboard.whats_next.verification_documents_modal.letter_of_enrollment.requirement_3',
	],
};

export type SampleDocumentType =
	| 'PASSPORT'
	| 'PROOF_OF_FUNDS'
	| 'TRANSCRIPT'
	| 'IELTS'
	| 'CAMBRIDGE'
	| 'TOEFL'
	| 'DUOLINGO'
	| 'OET'
	| 'PTE';

export const SAMPLE_DOCUMENTS = {
	PASSPORT: imgSampleDocumentsPassport,
	PROOF_OF_FUNDS: imgSampleDocumentsProofOfFunds,
	TRANSCRIPT: imgSampleDocumentsTranscript,
	IELTS: imgSampleDocumentsIelts,
	CAMBRIDGE: imgSampleDocumentsCambridge,
	TOEFL: imgSampleDocumentsToefl,
	DUOLINGO: imgSampleDocumentsDuolingo,
	OET: imgSampleDocumentsOet,
	PTE: imgSampleDocumentsPte,
};
