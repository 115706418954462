import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import {Icon} from '../../../Icon';
import style from './LoadingSpinner.module.css';

interface Props {
	mini?: boolean;
}

export function LoadingSpinner({mini}: Props) {
	const spinnerClassName = classNames(
		style.LoadingSpinner,
		mini ? style.mini : '',
	);
	return (
		<div className={spinnerClassName}>
			<Icon spin icon={faCircleNotch} size={mini ? '1x' : '6x'} />
		</div>
	);
}
